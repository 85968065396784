// InputRange.scss

.input-range {
    width: 100%;
    position: relative;

    .slider {
        padding: 0 !important;
        -webkit-appearance: none;
        width: 100%;
        height: 6px !important;
        border-radius: 5px;
        background: #2f353d;
        outline: none;
        opacity: 1;
        transition: opacity 0.2s;

        &:hover {
            opacity: 1;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            position: sticky;
            margin-top: 3px;
            margin-right: 3px;
            z-index: 20;
            appearance: none;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #4284f3;
            cursor: pointer;
        }

        &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #04aa6d;
            cursor: pointer;
        }
    }
}

// InputRangeSlider.scss

.input-range-slider {
    width: 100%;
    padding: 0 !important;
    height: 20px !important;
    border: unset !important;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #04aa6d;
        cursor: pointer;
    }
}

// Dots.scss

.dots {
    div {
        display: flex;
        position: absolute;
        width: 100%;
        height: 12px;
        width: 12px;
        top: 8px;
        border-radius: 6px;
        border: 3px solid;

        &:hover {
            opacity: 1;
        }

        span {
            margin-top: -20px;
            margin-left: -3px;
            font-size: 12px;
        }
    }

    div:nth-child(1) {
        left: calc(0%);
    }

    div:nth-child(2) {
        left: calc(25% - 3px);
    }

    div:nth-child(3) {
        left: calc(50% - 6px);
    }

    div:nth-child(4) {
        left: calc(75% - 9px);
    }

    div:nth-child(5) {
        left: calc(100% - 12px);
    }
}